<template>
    <div>

      <b-row >
          <b-col
            lg="12"
            md="12"
          >
            <div class="mt-2">
              <h3>Gestion de la fiche comptable : <span class="text-primary"> {{formatAssociation()}} </span></h3>
            </div>
          </b-col>
          <b-col
            lg="12"
            md="12"
          >
            <div class="mt-2" v-if="canViewBasedOnRole([Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role])">
              <p>Dans cette section, vous pouvez consulter le sommaire de cet fiche comptable en plus de pouvoir ajouter et retirer des produits qui y sont associées dans la section <i> Facturation détaillée</i> </p>
            </div>
            <div class="mt-2" v-else>
              <p>Dans cette section, vous pouvez consulter le sommaire de cet fiche comptable en plus de consulter les produits qui y sont associées dans la section <i> Facturation détaillée</i> </p>
            </div>
          </b-col>

        </b-row>
      <b-tabs class="mt-2" content-class="mt-2" justified>
        <b-tab title="Facturation détaillée">
        <b-row class="match-height">
          <b-col >
            <b-card>
            <b-row>
              <b-col cols="12">
                <div class="mt-2" >
                  <h3 class="float-left">Liste des produits</h3>
                  <b-button variant="success" class="float-right" v-b-modal.add-product-for-billingsheet v-if="canViewBasedOnRole([Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role])" > + Ajouter un produit </b-button>
                  <AddProductBillingSheet  :currentProducts="billsheetProducts" @submitProductBillingSheet="submitProductBillingSheet"></AddProductBillingSheet>
                </div>
              </b-col>
            </b-row>
            <group-billing-product :can-modified="canViewBasedOnRole([Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role])" v-for="item in billsheetProducts" :billing-product="item" :key="item.id" @remove="removeProductFromBillingSheet(item)"/>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
        <b-tab title="Sommaire de la facturation">
          <b-row class="match-height">
            <b-col
              lg="12"
              md="12"
            >
              <b-card  header-tag="header" class="mt-2" >
                <template #header >
                    <h3> Sommaire de la facturation </h3>
                </template>
                <b-row class="mt-1">
                  <b-col cols="6">
                    <h5 class="mb-1">Détail du prix de vente </h5>
                    <b-list-group>
                        <b-list-group-item>Montant total avant taxes  <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.amount;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item>Taxes <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.taxes;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item>Autres taxes <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.other_taxes;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item>TPS (sur montant avant taxes)<span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.gst;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item >TVQ (sur montant avant taxes) <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.qst;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item class="dark-line-item">Montant Total Brut (Prix de vente)  <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.gross_total;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item class="dark-line-item">Montant Total Net (Prix de vente au NET)  <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.net_total;}, 0))}}</span></b-list-group-item>
                      </b-list-group>
                  </b-col>
                  <b-col cols="6">
                    <h5 class="mb-1">Commission </h5>
                    <b-list-group>
                        <b-list-group-item>Commission  <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.commission;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item>TPS <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.commission_gst;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item>TVQ <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.commission_qst;}, 0))}}</span></b-list-group-item>
                        <b-list-group-item class="dark-line-item">Total commission à recevoir   <span style="float:right">$ {{ formatPrice(billsheetProducts.reduce((accumulator, object) => {return accumulator + object.commission_total;}, 0))}}</span></b-list-group-item>
                    </b-list-group>
                    <!--
                    <b-row class="mb-1" style="margin-top:4em">
                      <b-col cols="12">
                        <div class="mt-2" >
                          <h5 class="float-left">Autres frais (inclus dans la commission)</h5>
                          <b-button variant="success" size="sm" class="float-right" > Modifier </b-button>
                        </div>
                      </b-col>
                </b-row>

                <b-row >

                    <b-col cols="6">
                      <b-form-group
                        label="Frais carte de crédit"
                        label-for="transport-options"
                      >
                        <b-form-input
                          :disabled="true"
                          size="sm"
                          value="0"
                        />
                      </b-form-group>
                    </b-col>

                  <b-col cols="6">
                    <b-form-group
                      label="Frais administratif"
                      label-for="transport-options"
                    >
                      <b-form-input
                        :disabled="true"
                        size="sm"
                        value="0"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                -->
                  </b-col>
                </b-row>
                <hr>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText, BButton, BIconFileEarmarkEasel, BListGroup, BListGroupItem, BFormGroup, BFormInput } from 'bootstrap-vue'
import GroupBillingProduct from './GroupBillingProduct'
import AddProductBillingSheet from './AddProductBillingSheet'
import store from '@/store/index'
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {ref} from "@vue/composition-api/dist/vue-composition-api";
import { useRouter, useRoute } from 'vue-router'
import {GroupBilling} from "../../services/GroupBilling";
import {groupProduct} from "@/views/groups/group/GroupBilling/GroupProduct/GroupProduct";
const groupbilling=new GroupBilling();
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import groupCreationConfig from "@core/data/groupCreationConfig.js";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    GroupBillingProduct,
        BListGroup,
    BListGroupItem, BFormGroup, BFormInput,
    AddProductBillingSheet
  },
    props: {
    currentGroup: Object,
  },
  setup(props,context) {
    const router = context.root.$router;
    const route = context.root.$route;
    const {store} = useApplicationContext();

    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();
    const billsheetProducts = ref([])
    const associations = ref([])

    const fetchAssociations = () => {
      groupbilling.getAccounting(route.params.group_id).then(response => {
        associations.value = response.data.associations;
      })
    }
    fetchAssociations()

    const formatPrice = (value) => {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }

    const findAssociation = () => {
        let package_id = route.params.pack_id;
        let occup_id = route.params.occup_id;
        let traveler_id = route.params.traveler_id;

      let association = associations.value.find(element=>{
          return (element.package_id===package_id && element.occupation_type_id===occup_id && element.traveler_type===traveler_id);
        })
      return association;
    }

    const formatAssociation = ()  =>{
      let association = findAssociation();

      if (!association) {
        return '';
      }

      let travelerType = association.traveler_type;
      const category = groupCreationConfig.childrenCategories[0].find(x => x.value === travelerType)
      if (category) {
        travelerType = category.text
      }

      return `${association.package_name} - ${association.occupation_type} - ${travelerType}`
    }

    const fetchProducts = () => {
      groupbilling.getBillingSheetProducts(route.params).then(response => {
        billsheetProducts.value = filterProducts(response.data);
      })
    }
    fetchProducts()

    const filterProducts = (products) => {
      let productsList = []
      let feesList = [];
      products.forEach(product => {
        if (product.type == 'PRODUCT') {
          productsList.push(product)
        } else if (product.type == 'ADMIN_FEE_IN' || product.type == 'ADMIN_FEE_OUT') {
          feesList.push(product)
        } else {
          productsList.push(product)
        }

      })
      return productsList.concat(feesList)

    }

    const currentAuthUser = ref({});
    currentAuthUser.value = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`];
    const canViewBasedOnRole = (roles) => {
      try {
        let role = currentAuthUser.value.role.role
        return roles.includes(role)
      } catch {
        return false;
      }
    }

    const updateProductBillingSheet = async (products) => {
      try {
        await groupbilling.updateBillingSheetProducts(route.params.group_id, route.params, products);
        displaySuccessMessage('La fiche comptable a été mise à jour avec succès');
      } catch (e) {
        console.error(e)
        displayErrorMessage("La sauvegarde a échoué");
      }
    }

    const submitProductBillingSheet = async (product) => {
      let updatedProducts = {
        products:[],
      };
      billsheetProducts.value.forEach(element => {
        updatedProducts.products.push(element.id)
      });
      updatedProducts.products.push(product.id)

      await updateProductBillingSheet(updatedProducts)
      billsheetProducts.value.push(product)
    }

    const removeProductFromBillingSheet = async (product) => {
      let updatedProducts = {
        products:[],
      };
      billsheetProducts.value.forEach(element => {
          updatedProducts.products.push(element.id)
      });
      const productIdIndex = updatedProducts.products.indexOf(product.id)
      if (productIdIndex > -1) {
        updatedProducts.products.splice(productIdIndex, 1)
      }

      await updateProductBillingSheet(updatedProducts)
      const productIndex = billsheetProducts.value.indexOf(product)
      billsheetProducts.value.splice(productIndex, 1)
    }

    return {
      formatAssociation,
      submitProductBillingSheet,
      removeProductFromBillingSheet,
      billsheetProducts,
      canViewBasedOnRole,
      Roles,
      formatPrice
    }
  },
  data() {
    return {
      hasBasicInfo: false,
    }
  },
}
</script>

<style>
[dir] .list-group-item {
    background-color: #fff;
    border: 1px solid rgba(34, 41, 47, 0.125);
    padding: 5px;
}
.dark-line-item {
  background-color: #d8d6de;
}
</style>
